.foot-ex-links-container{
    text-align: left;
    max-width: 1100px;
    margin: 2rem auto;
    padding: 0 1rem;
}

.numbers-footer{
    counter-reset: listcounter;
    list-style: none;
    padding: 0;
    margin: 0px 0;
    text-align: left;
}

.numbers-footer p{
    margin-left: 67px;
}

.numbers-footer li{
    line-height: 32px;
    clear: both;
    margin: 1rem 3rem !important;
}

.numbers-footer li:before{
    content: counter(listcounter);
    counter-increment: listcounter;
    clear: both;
    display: inline-block;
    width: 30px;
    height: 33px;
    margin: 0px 1rem;
    font-size: 26px;
    line-height: normal;
    color: #A3CD3A;
    text-align: center;
    border: 2px solid #A3CD3A;
    border-radius: 10px;
}

section.get-loan {
    color: #ffffff;
    background-color: #006D38;
    padding: 36px 0;
    text-align: center;
}

.get-loan-nobg{
    margin-top: 3rem;
}

.btn-m {
    border-radius: 5px;
    font-family: "CoreSansM55-webfont", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-width: 0;
    border-style: solid;
    font-size: 1.6rem;
    line-height: 1.3;
    color: #ffffff;
    background-color: #A3CD3A;
    border-color: transparent;
}