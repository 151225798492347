.footer-popup .popup-content {
  height: fit-content;
  margin: auto !important;
  width: 70%;
}

.footer-popup img {
  right: 0;
}

@media (max-width: 850px) {
  .f-popup-container {
    flex-direction: column-reverse;
  }
  .text-f-popup-container {
    margin-bottom: 1rem;
  }
}

@media (max-width: 550px) {
  .f-popup-container {
    padding: 0;
  }
}
