// react tabs styles
.react-tabs {
    -webkit-tap-highlight-color: transparent;
  
    &__tab-list {
      border-bottom: 1px solid #aaa;
      margin: 0 0 10px;
      padding: 0;
    }
  
    &__tab {
      display: inline-block;
      border: 1px solid transparent;
      border-bottom: none;
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 6px 12px;
      cursor: pointer;
  
      &--selected {
        background: #fff;
        border-color: #aaa;
        color: black;
        border-radius: 5px 5px 0 0;
      }
  
      &--disabled {
        color: GrayText;
        cursor: default;
      }
  
      &:focus {
        box-shadow: 0 0 5px hsl(208, 99%, 50%);
        border-color: hsl(208, 99%, 50%);
  
        &:after {
          content: "";
          position: absolute;
          height: 5px;
          left: -4px;
          right: -4px;
          bottom: -5px;
          background: #fff;
        }
      }
    }
  
    &__tab-panel {
      display: none;
  
      &--selected {
        display: block;
      }
    }
  }  
// react tabs styles END

.router-loading{
    height: calc(100vh - 405px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.router-loading > div{
    width: 50px;
    height: 50px;
}

.password-wrapper{
    display: flex;
    align-items: center;
}

.show-password{
    cursor: pointer;
    margin-left: 1rem;
}

.btn-minimal-width-disabled{
    padding: 1rem 2rem;
    color: white;
    width: 125px;
    text-align: center;
    font-size: 1.2rem !important;
    font-weight: 600;
    cursor: not-allowed;
    background-color: lightgray;
}

.btn-minimal-width{
    padding: 1rem 2rem;
    color: white;
    width: 125px;
    text-align: center;
    font-size: 1.2rem !important;
    font-weight: 600;
    cursor: pointer;
}

.btn-full-width{
    width: 100%;
}

.smaller-button{
    font-size: 1rem !important; 
    width: 215px;
}

.app-container{
    background-color: #eceef2;
    border-top: 2px solid rgba(217,217,217,0.44);
    margin-bottom: -2.5rem;
    padding-bottom: 3rem;
    position: relative;
}

.fill-demo{
    position: absolute;
    top: 3rem;
    right: 2rem;
    background-color: #A3CD3A;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    box-shadow: 1px 1px 5px gray;
    cursor: pointer;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
}

.fill-demo:hover{
    background-color: #0E672E;
}

.forgot-layout{
    max-width: 700px;
    margin: 0 auto;
}

.forgot-layout a{
    color: #A3CD3A;
    text-decoration: underline;
}

.btn-minimal-width{
    background-color: #A3CD3A;
}

.btn-forgot{
    width: 50%;
    margin: 1rem auto !important;
}

.new-navbar{
    position: absolute;
    right: 0;
    color: black;
    top: 33px;
    display: flex;
}

.new-navbar a{
    margin: 0 1rem;
    border-bottom: none;
}

.account-container{
    display: flex;
    align-items: center;
    margin: 0 4rem;
    justify-content: center;
    flex-direction: column;
}

.account-container h3{
    font-size: 1.2rem;
}

.right-dash{
    text-align: center;
    display: flex;
    flex-direction: column;
}

.button-container-dash {
  @media screen and (max-width: 768px) {
    padding: 2rem 0 0 0;
  }
  padding: 2rem 2.5rem 0 2.5rem;

    display: grid;
    grid-template-columns: 1.2fr .8fr;
    background-color: white;
}

.select-option{
    padding: .5rem .5rem;
    cursor: pointer;
    background-color: white;
    color: #a3cd3b;
    border-bottom: 1px solid #a3cd3b;
    transition: all 200ms ease;
    font-family: Manrope;
}

.select-option:nth-child(2){
    margin: 0 10px 0 10px;
}

.select-option:hover{
    background-color: #A3CD3A;
    color: white;
    transition: all 200ms ease;
}

.payment-option-buttons:hover{
    background-color: #A3CD3A;
    color: white;
    transition: all 200ms ease;
}

.active{
    background-color: #A3CD3A !important;
    color: white !important;
}

.dashboard-default{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1030px;
}

.dash-top-container{
    display: flex;
    margin: 1rem;
    justify-content: center;
    flex-direction: column;
    min-width: 90%;
    max-width: 90%;
}

.dash-bottom-container{
    display: flex;
    margin-top: 1rem;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}

.contrato{
    background-color: #c6c6c6;
}

.contrato-table{
    background-color: #c6c6c6;
    border-radius: 5px;
    padding: 1rem 0 0;
    height: 270px;
    z-index: 2;
    overflow-y: hidden;
}

.contrato-table:hover{
    overflow-y: auto;
}

.dash-box{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    margin: 20px 0rem;
}

.empty-box{
    width: 263px;
    height: 287px;
    background-color: lightgray;
}

.terms-li li{
    list-style: none;
}

.popup-overlay{
    overflow-y: scroll;
}

.title{
    color: #0E672E;
    font-weight: bold;
    margin: 1rem 0;
    font-size: 1.4rem;
}

.bottom-title{
    font-size: 1.2rem;
    color: white;
}

.bottom-title-empty{
    background-color: #f2f2f2;
    font-size: 1.2rem;
    color: #0E672E;
}

.prestamo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: #A3CD3A 1px solid;
    border-radius: 5px;
    padding: 1rem;
}

.prestamo-bars{
    display: flex;
    margin-top: 1rem;
}

.prestamo-bar-disponible{
    height: 20px;
    background-color: #A3CD3A;
}

.prestamo-bar-uso{
    height: 20px;
    background-color: #006d38;
}

.prestamo-texto{
    display: flex;
    width: 500px;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 1rem;
}

.prestamo-disponible{
    text-align: left;
}

.prestamo-en-uso{
    text-align: right;
}

.prestamo-disponible p:nth-child(2), .prestamo-en-uso p:nth-child(2){
    font-weight: bold;
}

.importe-disponible .importe-letra{
    background-color: rgb(223, 223, 223);
    padding: 1.8rem 10px;
}

.importe-disponible .importe-numero{
    padding: 1.8rem 10px;
    min-width: 79.3px;
}

.importe-en-uso .importe-letra{
    background-color: #A3CD3A;
    color: white;
    padding: 1.8rem 10px;
}
    
.importe-en-uso .importe-numero{
    padding: 1.8rem 10px;
    min-width: 79.3px;
}

.importe-letra{
    max-width: 85px;
}

.green-border {
    border: #A3CD3A 1px solid;
}

.loan-number{
    margin-top: 1rem;
    padding-left: 3rem;
    text-align: left;
}

.loan-number p{
    font-size: 1.2rem;
}

.parcialidad{
    display: flex;
    background-color: white;
    padding: 1rem;
    justify-content: space-between;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

}

.parcialidad-container{
    margin: .5rem 2rem 0 2rem;
}

.contract-container {
    width: 100%;
    text-align: right;
    margin-bottom: 2rem;
}

.dark-green-title {
    color: #0e672f;
    font-weight: bold;
}

.contract-container a {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 2.5rem;
}

.contract-container p {
    margin-right: 2.5rem;
}

.parcialidad-container p:nth-child(1){
    font-size: 1.2rem;
}
.parcialidad-container p:nth-child(2){
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px 0 !important;
}
.parcialidad-container p:nth-child(3){
    font-size: 1rem;
}

.dash-banner{
    width: 100%;
    margin: 1rem 0 0;
    cursor: pointer;
}

.dash-banner video {
    width: 100%;
    // height: auto;
}

small{
    font-size: 0.8rem !important;
}

.avance{
    background-color: white; 
    border: #A3CD3A 1px solid;
    border-radius: 5px;
    padding: 1rem;
    height: 191px;
}

.edo-cuenta{
    background-color: #A3CD3A;
    border: #A3CD3A 1px solid;
    border-radius: 5px;
    padding: 1rem 0 0;
    height: 270px;
    z-index: 2;
    overflow-y: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.edo-cuenta:hover{
    overflow-y: auto;
}


.edo-cuenta td{
    padding: 8px;
}

.edo-cuenta-empty{
    background-color: #A3CD3A;
    border: #A3CD3A 1px solid;
    border-radius: 5px;
    padding: 1rem 0 0;
}

.tabla{
    background-color: white;
}

th, td {
    padding: 10px;
    text-align: center;
    font-size: 0.8rem;
}

.row-non{
    background-color: white;
}

.row-gray{
    background-color: #EDEDED;
}

.row-par{
    background-color: #ebfea69c;
}

.flex-horizontal{
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.flex-horizontal a{
    color: black;
    text-decoration: underline;
}

.slider-container{
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    color: black; 
    padding: 2rem;
    min-width: 320px;
    border-radius: 20px 0 0 20px;
    height: 704px;
}

.slider-container h2{
    text-align: left;
    color: black;
    font-size: 3.5rem;
    margin: 0;
}

.slider-container h3{
    text-align: right;
    color: black;
    font-size: 2.1rem;
    margin: 0;
}

.title-winput{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 1rem;
}

.title-winput input{
    width: 50px;
    color: #000000;
}

.title-winput.calculator-title-input {
    color: white;
}

.index-calculador-slider .rangeslider__label-item{
    color: white;
    font-weight: bold;
}

.phone-number-repeated {
    color: red;
    width: 250px;
}

.wrong-number {
    color: red;
}

.buro-container{
    padding: 2rem;
    width: 50%;
    text-align: left;
    font-size: 1.1rem;
}

.educacion-fin{
    display: flex;
    padding: 1rem;
}

.educacion-fin input{
    font-size: 1rem;
    text-align: center;
}

.buro-title{
    margin: 1rem 0;
}

.buro-nip{
    font-weight: bold;
    margin: 1rem 0;
}

.buro-acepto{
    margin: 1rem 0;
}

.buro-terminos{
    font-size: 0.7rem;
}

.insurance-line {
    width: 100%;
    display: flex;
    align-items: center;
}

.insurance-line input {
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid black;
  padding: 1px 2px;
  box-sizing: content-box;
  cursor: pointer;
  font-size: 14px;
}

.insurance-line input[type="checkbox" i]:checked{
    appearance: none;
    appearance: none;
    top: 0;
    line-height: 14px;
    margin: 0;
    -webkit-appearance: none;
    padding: 1px 2px;
    border: 2px solid #A3CD3A;
}
.insurance-line input[type="checkbox" i]:checked::before{
    content: '\2714';
    color: #A3CD3A;
    font-weight: bold;
    font-size: 14px;
}

.check-contrato{
    max-width: 952px;
    min-width: 280px;
    height: 60px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin: 1rem auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
}

.check-contrato input[type="checkbox" i]{
    appearance: none;
    -webkit-appearance: none;
    border: 2px solid black;
    padding: 1px 2px;
    box-sizing: content-box;
    cursor: pointer;
}
.check-contrato input[type="checkbox" i]:checked{
    appearance: none;
    top: 0;
    line-height: 14px;
    margin: 0;
    -webkit-appearance: none;
    border: 2px solid #A3CD3A;
    padding: 1px 2px;
}
.check-contrato input[type="checkbox" i]:checked::before{
    content: '\2714';
    color: #A3CD3A;
    font-weight: bold;
    font-size: 14px;
}

.check-contrato span{
    color: darkslategray;
}

.check-contrato p, .check-contrato span{
    margin: 0 1rem;
}

.cargo-container{
    padding: 2rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contract-layer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 952px;
}

.resume-dashboard-red{
    background-color: #006d38;
    color: white;
    text-align: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 0 1rem 1rem;
    min-width: 275px;
    font-size: 1rem;
    height: fit-content;
    width: 70%;
    margin-bottom: 10px;
}

.contract-resume-container{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.resumen-contrato{
    display: flex;
    flex-direction: column;
}

.contrato-top{
    background-color: #93d500;
    display: flex;
    padding: 1rem;
    border-radius: 20px 20px 0px 0px;
    justify-content: space-evenly;
}

.contrato-bottom{
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    background-color: white;
    border-radius: 0 0 20px 20px;
    align-items: center;
}

.contrato-bottom a{
    margin-left: 1rem;
}

.resumen-left{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.resumen-left h2{
    margin-left: 2rem;
}

.data{
    display: flex;
    padding: 0 1rem;
    justify-content: center;
}

.data-title{
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
}

.data-margin{
    padding: 1rem;
    width: 190px;
    text-align: center;
}

.resumen-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.resumen-right h5{
    color: black;
    width: 100%;
    background-color: white;
    padding: 1rem 0;
    margin: 0;
    border-bottom: 1px solid lightgray;
    font-size: 1rem;
}

.boton-periodicidad{
    background-color: white;
    color: #0E672E;
    padding: 1rem 2rem;
    border-radius: 7px;
    margin: 0.5rem;
    border: 1px solid #0E672E;
}

.boton-periodicidad:hover{
    background-color: #0E672E;
    color: white;
}



.period-active{
    background-color: #0E672E;
    color: white;
}

.id-container{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1rem 5rem;
}

.id-top-container{
    width: 100%;
    max-width: 1400px;
    align-self:center;
}

.id-top-container h2{
    text-align: center;
    font-size: 2rem;
    font-weight: 300;
    margin-block-start: 1rem !important;
    margin-block-end: 0 !important;
}

.id-top-container h3{
    text-align: center;
    font-size: 2rem;
    margin-block-start: 0 !important;
    margin-block-end: 1rem !important;
}

.top-instructions{
    width: 100%;
}
.top-instructions p, .top-instructions h5{
    text-align: center;
}

.instructions{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.ife-description{
    background-color: white;
    padding: 2rem;
    max-width: 100%;
    margin: 0 10px;
    grid-column: 1 / span 2;
    @media screen and (max-width: 850px) {
      padding: 2rem 1rem;
    }
}

.ife-container{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.ife-container-instructions {
  display:flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.ife-container img{
    width: 200px;
    height: 100%;
    padding: 1rem;
}

.ife-content {
    display: grid;
    padding-top: 10px;
    grid-template-columns: 1fr 1fr;
}

.ife-content > * {
    padding-top: 20px;
}

.selfie-content {
    display: flex;
    flex-direction: column;
}

.upload-container{
    display: flex;
    justify-content: center;
    max-width: 1400px;
    align-self:center;
    width: 100%;
}

.upload-section{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 2rem;
    margin: 2rem 10px;
    width: 100%;
    box-sizing:border-box;
    border: 1px solid green;
}

.upload-title{
    font-size: 1.5rem;
    font-weight: bold;
}

.id-process-status{
    font-weight: bold;
    margin-right: 0%;
    max-width: 30%;
    margin-left: 20%;
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
}

.id-process-status.red {
    color: red;
}

.upload-description{
    display: flex;
}

.upload-description img{
    width: 60px;
    height: 60px;
    margin: 0 1rem;
}

.help-banner{
    background-color: #A3CD3A;
    border-radius: 20px;
    box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.5);
    height: fit-content;
    width: 100%;
    max-width: 750px;
}

.help-banner h2, .help-banner h4{
    color: white;
}

.help-banner{
    text-align: center;
    margin: 1rem auto;
}

.speedometer{
    width: 300px !important;
    height: 182px !important;
}

.pagar-container{
    display: flex;
    flex-direction: column;
    border-radius: 20px 0 0 20px;
    padding: 1rem 0;
    text-align: justify;
    overflow-y: auto;
    -ms-overflow-style: none;  // IE 10+
    scrollbar-width: none;  // Firefox
    color: black;
}

.pagar-container::-webkit-scrollbar { 
    display: none;  // Safari and Chrome
}

.pagar-container h3{
    font-size: 2rem;
    color: white;
    font-weight: bold;
    margin: 0.2rem;
}

.pagar-title {
    padding-bottom: 1rem;
}

.pagar-text-box{
    background-color: white;
    color: black;
}

.liquidar-prestamo{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.liquidar-prestamo span{
    color: white;
    font-size: 1rem;
    margin-right: 2rem;
}

.options-buttons{
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
}

.dashboard-button {
    background-color: white;
    font-size: 1;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #a3cd3b;
    color: #a3cd3b;
    width: 90%;
    max-width: 50%;
    height: 50px;
    font-family: Manrope;
    margin-top: 10px;

    @media screen and (max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }
}

.selected-button {
  background-color: #a3cd3b;
  color: white;
}

.payment-option-buttons{
    background-color: white;
    color: #a3cd3b;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid #a3cd3b;
    cursor: pointer;
    padding: 10px;
    width: 90%;
    max-width: 30%;
    height: 100%;
    font-family: Manrope;

    @media screen and (max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }
}

.payment-option-buttons:hover{
    background-color: #A3CD3A;
    color: white;
    transition: all 200ms ease;
}


.right-dash-new {
    float: left;
}    

.right-dash-new > div {
    float: left;
}

.right-dash-dropdown{
    display: none;
    width: 50%;
    margin: 0 auto;
}

.right-dash-dropdown .Dropdown-control,
.right-dash-dropdown .Dropdown-option.is-selected{
    background-color: #A3CD3A;
    color: white;
    font-weight: bold;
}

.right-dash-dropdown .Dropdown-option {
    background-color: white;
    font-weight: bold;
    color: #A3CD3A;
}

.right-dash-dropdown .Dropdown-option:hover{
    background-color: #A3CD3A;
    color: white;
    font-weight: bold;
    transition: all 200ms ease;
}

.right-dash-dropdown .Dropdown-arrow{
    border-color: white transparent transparent;
}

.right-dash-dropdown.is-open .Dropdown-arrow{
    border-color: transparent transparent white;
}

.right-dash-dropdown .Dropdown-menu {
    border-top: 1px solid white;
}

.op-left-buttons, .op-right-buttons{
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.bank-images{
    display: flex;
}

.bank-images img{
    height: 20px;
    width: auto;
    padding: 0 15px;
}

.dashboard-gray-button{
    background-color: #C6C6C6;
    color: white;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.5);
    outline: none;
    border-color: transparent;
    cursor: pointer;
}

.active-dash-button, .dashboard-gray-button:hover{
    background-color: #93d501;
}

.separator{
    width: 60px;
    border: 2px solid white;
    margin: 20px 0;
    justify-content: flex-start;
}

.liquidate-container{
    background-color: white;
    color: black;
    border-radius: 20px;
    display: flex;
}

.liquidate-left{
    margin: 1rem;
    width: 300px;
}

.liquidate-left h4{
    margin: 1rem;
    text-align: left;
}

.liquidate-left hr{    
    width: 100%;
    margin: 1rem 0;
}

.liquidate-resume{
    border: 1px solid #A3CD3A;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.liquidate-resume:last-child {
    border-bottom: none;
}

.liquidate-resume p{
    font-size: 1rem;
}

.liquidate-title {
    background-color: #A3CD3A;
    color: white;
}

.liquidate-values{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #A3CD3A;
}

.liquidate-values.no-under-border {
    border-bottom: none;
}

.liquidate-values p{
    text-align: left;
}

.bold{
    font-weight: bolder;
    font-size: 1.7rem;
}

.liquidate-right{
    margin: 1rem;
    width: 485px;
}

.react-tabs__tab{
    text-align: center;
    vertical-align: middle;
    display: table-cell;
}

.react-tabs__tab--selected {
    background: #0094AA !important;
    //border-color: #A3CD3A !important;
    color: white !important;
    border-radius: 9999px !important;
}

.charge-resume p{
    margin: 2rem auto !important;
}

.charge-resume-true p{
    margin: 1rem;
}

.charge-resume button{
    width: 100%;
}

.flex-distributed{
    display: flex;
    justify-content: center;
    flex-direction: row;
    // margin: .5rem 1.5rem;
    flex-wrap: wrap;
    align-items: center;
}

.flex-distributed img{
    width: 90px;
}

.grid-image {
    width: 60px;
    margin: 0 5px;
}

.grid-image img{
    max-width: 100%;
    height: auto
}

.btn-cash-payment {
    margin-top: 1rem;
}

.cash-option-section {
    margin-top: 2rem;
}

.return-dash{
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
    margin: 0 auto;
    padding-top: 1rem;
    font-size: 1.2rem;
}

.return-dash:hover{
    color: white;
}

.modal-header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.modal-header p{
    width: fit-content;
    background-color: #0E672E;
    color: white;
    padding: 1rem 4rem;
    margin: 0;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1001;
    background-color:rgba(0,0,0,0.67);
}

.modal-main-container {
    width: 800px;
    margin: auto;
    margin-top: 5rem;
}

.modal-title-line {
    padding: 0 1rem;
}

.modal-title {
    font-weight: bold;
    text-align: left;
    float: left;
    font-size: 1.2rem;
}

.modal-close-x {
    font-weight: bold;
    text-align: right;
    float: right;
    cursor: pointer;
    margin-top: 1.5rem;
    width: 1rem;
}

.modal-content{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 0 2rem 2rem 2rem;
    overflow-y: auto;
    border-radius: 15px;
}

.modal-content p{
    padding: 0 1rem;
}

.modal-bottom-container{
    display: flex;
}

.modal-accordion{
    padding: 1rem;
    width: 350px;
}

.modal-no-pad {
    padding: 0 !important;
}

.move-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 1rem 0;
    text-align: justify;
    color: black;
    width: 100%;
    box-sizing: border-box;
}

.move-container p, .move-titles p{
    font-size: 1rem;
    margin: 0;
}

.move-container h3{
    font-size: 3rem;
    color: #0E672E;
    font-weight: bold;
    padding: 1rem;
}

.move-sent{
    padding-top: 1.5rem;
    text-align: center;
}
.move-sent p:nth-child(1){
    font-size: 1.5rem;
}
.move-sent p:nth-child(2){
    font-size: 1.3rem;
    margin-bottom: 10px !important;
}
.move-sent p:nth-child(4){
    font-size: 1.2rem;
}
.move-sent p:nth-child(5){
    margin-top: 12px !important;
    font-size: 0.8rem;
}
.move-sent a{
    color: #A3CD3A;
    text-decoration: underline;
}

.move-medium-size{
    font-size: 3rem !important; 
}

.move-md-size{
    font-size: 1.2rem !important; 
}

.move-big-size{
    font-size: 4rem !important;
}

.move-requirements{
    display: flex;
    padding: 0 3rem;
    margin: 1rem 0;
    align-items: center;
}

.move-number{
    font-size: 3.5rem !important;
    color: white;
    margin-right: 1rem !important;
}

.green{
    color: white;
}

.check-requirement{
    margin: 0.2rem 2rem;
}

.check-requirement p{
    color: white;
    margin: 1rem;
    font-weight: 700;
}

.move-titles{
    padding: 2rem 3rem;
}

.move-option-container{
    display: flex;
    flex-direction: column;
}

.modal-move {
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.right-move-option{
    width: 50%;
    top: 0;
    left:0;
    position: relative;
    margin: 0 auto;
}

.fa-times-move {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
}

.left-move-option{
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 769px) {
      margin: 0 1rem;
    }
}

.flex-centered{
    display: flex;
    align-items: center;
}

.move-option-title{
    margin: 0 0 1rem;
}

.move-option-title img{
    width: 50px;
    margin-right: 2rem;
    filter: brightness(0.5);
    -webkit-filter: brightness(0.5);
}

.move-vencimiento{
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 6px 0 1rem 0;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
}

.move-options-container{
    display: flex;
    max-width: 100%;
    justify-content: center;
}

.move-nuevo-vencimiento{
    border: 1px solid #a7ce3a;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: white;
    margin: 0 5px;
    box-sizing: border-box;
}

.move-selected{
    border: 1px solid white;
    background-color: #A7CE3A;
}

.move-selected p, .move-selected small{
    color: white !important;
}

.move-selected hr{
    border: 1px solid white !important;
}

.move-nuevo-vencimiento p{
    color: black;
}

.move-select-button{
    background-color: #A3CD3A;
    outline: 1px solid white;
    font-size: 1rem !important;
    color: white !important;
    padding: 0.5rem !important;
    font-weight: bold !important;
    width: 200px;
    font-family: Manrope;
}

.info-blank-div{
    color: black;
    text-align: center;
}
.info-blank-div p:nth-child(1){
    font-size: 1.8rem;
}
.info-blank-div p:nth-child(2){
    font-size: 1.8rem;
}

.big-loader > div{
    width: 150px !important;
    height: 150px !important;
}

.price-move {
    justify-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
}

.price-move p{
    text-align: center !important;
}

.price-move p:nth-child(1){
    font-size: 1.2rem;
    font-weight: lighter;
    margin: 0;
    padding: 0;
}
.price-move p:nth-child(2){
    font-size: 1.5rem;
    font-weight: bold;
    padding: 5px 0;
}
.price-move p:nth-child(3){
    font-size: .8rem;
    padding-bottom: 5px;
}
.price-move p:nth-child(4){
    padding-bottom: 5px;
    font-size: 0.85rem;
}

.price-move hr{
    border: 1px solid black;
    width: 80%;
}

.price-move small{
    color: black;
}

.move-terms{
    margin: 20px 0 0 0 !important;
}

.move-terms-bold{
    font-weight: bold;
    margin-top: .5rem !important;
}

.blank-div{
    height: 100%;
    border-radius: 20px;
    background-color: white;
    margin-left: 1rem;
    justify-content: center;
    display: flex;
    align-items: center;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.extension-error{
    color: darkslategray;
    padding: 1rem;
}
.extension-error p{
    font-size: 1.3rem;
    text-align: center;
}

.will-pay-options{
    border-radius: 20px;
    background-color: white;
    margin-left: 1rem;
    padding: 2rem;
    color: black;
    height: 100%;
    max-height: 550px;
    overflow-y: auto;
}

.tabs-underlined{
    border-bottom: 1px solid #A3CD3A;
    list-style-type: none;
    padding: 0.5rem;
    cursor: pointer;
    text-align: center;
}

.more-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    text-align: center;
    font-size: 1rem;
}

.more-container-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    @media screen and (min-width: 768px) {
      align-items: center;
    }
}

.loading-calc{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.more-button {
    margin-top: 2.8rem;
}
.btn.more-button-mobile{
    display: none;
}

.more-btn-amount-group {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
}

.more-group-left {
    margin-top: 1rem;
}

.more-total{
    font-size: 1.8rem;
    margin-top: .5rem !important;
}

.calc-amounts{
    margin: 0 0 1rem 0;
}

.calc-amounts p{
    margin: 10px 0 !important;
}

.left-monto-option{
    display: flex;
    flex-direction: column;
    border-radius: 20px 0 0 20px;
    width: 48%;
}

.left-monto-option hr{
    margin-top: 15px;
    margin-bottom: 15px;
}
.left-monto-option p{
    text-align: left;
}
.left-monto-option .move-md-size{
    font-size: 2.5rem !important;
}

.right-monto-option{
    background-color: white;
    height: 80%;
    width: 40%;
    display: flex;
    align-items: center;
}

.amount-calc-title{
    font-size: 2rem !important;
    margin: 0;
    text-align: left;
}
.amount-calc-subtitle{
    font-size: 2rem;
    text-align: left;
}

.big-amount-calc{
    text-align: right;
    font-size: 2.6rem;
    margin: 10px 0 !important;
    font-weight: bold;
}

.more-option-title{
    margin: 2rem 0 0 0;
}

.new-loan{
    display: flex;
    justify-content: center;
    width: 100%;
}

.amount-calculator-info{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 1rem 0;
    background-color: white;
    width: 100%;
    margin-top: 2.3rem;
}

.amount-info-row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 7px 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.amount-info-row strong{
  font-size: 14px;
}

.new-loan td{
    padding: 8px;
}

.new-loan-left{
    text-align: right;
    margin-right: 1rem;
}

.new-loan-right{
    margin-left: 1rem;
    text-align: left;
}

.react-tabs__tab {
    padding: 6px 10px !important;
    font-size: 12px !important;
}

.profile-top-home{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.profile-top-home h3{
    font-size: 2rem;
    font-weight: bold;
}

.profile-top-home h3, .profile-top-home p{
    margin: 0;
}

.profile-home{
    display: flex;
    background-color: white;
    max-width: 925px;
    margin: auto;
}

.left-profile{
    width: 50%;
    display: flex;
    box-shadow: 3px 0px 5px -5px #000;
}

.left-profile img{
    width: 80%;
    margin: auto;
}

.right-profile{
    width: 50%;
    padding: 3rem 5rem;
}

.right-profile h4{
    font-size: 1.5rem;
    font-weight: 600;
}

.pfield-title{
    color: gray;
}

.pfield-content{
    font-weight: bold;
    font-size: 1rem;
}

.profile-field, .profile-field-editable{
    margin-bottom: 2rem;
}

.profile-field{
    margin: 2rem 0;
}

.profile-field-editable{
    display: flex;
    align-items: center;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
}

.left-fields{
    width: 100%;
    margin-right: 1rem;
}

.profile-edit-button{
    width: 120px !important;
    min-width: 120px !important;
    background-color: #A3CD3A;
    cursor: pointer;
}

.profile-edit-button-blocked{
    width: 120px !important;
    min-width: 120px !important;
    background-color: gray;
    cursor: not-allowed;
}

.return-to-profile{
    cursor: pointer;
    text-align: center;
}

.return-to-profile p{
    text-decoration: underline;
    color: #A7CE3A;
}

.change-payment-options{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10rem 0;
}

.payment-type{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    cursor: pointer;
}

.payment-type img{
    width: 15px;
}

.payment-type div{
    display: flex;
    align-items: center;
}

.payment-type div img{
    width: 40px;
    margin-right: 1rem;
}

.payment-type div p{
    margin: 0;
}

.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    min-height: calc(100vh - 73px - 316px) ;
    background-image: url(../../assets/img/login/background.jpg);
    background-size: cover;
}

.rightLogin{
    width: 50%;
    max-width: 400px;
}

.leftLogin{
    width: 50%;
    text-align: left;
    max-width: 880px;
    padding: 2rem 0;
    margin-right: 100px;
}

.friends-container{
    text-align: left;
    width: 400px;
    margin-top: 3rem;
    box-shadow: 0px 2px 5px lightgrey;
    border-radius: 20px;
}

.top-friends{
    color: white;
    background-color: #A3CD3A;
    padding: 2rem;
    border-radius: 20px 20px 0 0;
}

.top-friends p{
    font-size: 2rem;
}

.bottom-friends{
    display: flex;
    background-color: white;
    padding: 2rem;
    border-radius: 0 0 20px 20px;
}

.bottom-friends img{
    width: 50px;
}

.bottom-friends a{
    color: #A3CD3A;
}

.accordion__button {
    width: auto !important;
}

.buttons-wrapper{
    display: flex;
    align-items: center;
}

.preview-image {
    padding: 15px;
    background-color: #eceef2;
    margin-right: 30px;
    text-align: center;
    background-position: center;
    background-size: cover;
}


.button-wrapper-id p{
    font-size: 0.8rem !important;
}

.button-wrapper-id.continue{
    margin-left: auto;
    margin-right: auto;
}

.document-uploaded{
    background-color: rgb(136, 207, 0);
    max-width: fit-content;
    padding: 0.5rem 1rem;
    color: white;
    margin: 1rem auto;
    border-radius: 10px;
}

.segment-value{
    font-size: 12px !important;
}

.manteinance-page{
    margin: 80px 4rem 0;
    display: flex;
    text-align: left;
    align-items: center;
}

.left-manteinance{
    width: 50%;
    text-align: center;
}

.left-manteinance h1{
    font-size: 2rem;
    color: #A3CD3A;
    margin: 0;
}

.left-manteinance h2{
    margin: 1rem 0;
    font-size: 3rem;
    font-weight: bold;
    color: #0E672E;
}

.left-manteinance h3{
    font-size: 1.2rem;
    color: #6E7273;
}

.left-manteinance h4{
    font-size: 0.9rem;
    color: #6E7273;
}

.right-manteinance{
    width: 50%;
    height: 100%;
}

.right-manteinance img{
    width: 400px;
    position: absolute;
    bottom: 0;
}

.error-page{
    margin: 100px 0;
    position: relative;
}

.error-page h1{
    font-size: 5rem;
}

.error-page h2{
    font-size: 2.5rem;
    margin: 1rem 0;
}

.error-page h3{
    font-size: 1.5rem;
    margin: 1rem 0;
}

.return-error-button{
    background-color: #A3CD3A;
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: fit-content;
    margin: 2rem auto 0;
}

.background-error-icon{
    font-size: 7rem;
    color: lightgray;
}

.loan-container{
    display: flex;
    width: 950px;
    margin: 1rem auto;
    box-shadow: 4px 4px 20px grey;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.loan-title{
    display: flex;
    align-items: center;
}

.calculator-info-loan{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    background-color: white;
    border-radius: 0 20px 20px 0;
    -webkit-border-radius: 0 20px 20px 0;
    -moz-border-radius: 0 20px 20px 0;
    -ms-border-radius: 0 20px 20px 0;
    -o-border-radius: 0 20px 20px 0;
}

.left-loan{
    border-radius: 20px 0 0 20px;
    -webkit-border-radius: 20px 0 0 20px;
    -moz-border-radius: 20px 0 0 20px;
    -ms-border-radius: 20px 0 0 20px;
    -o-border-radius: 20px 0 0 20px;
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: white;
    color: black;
    border-right: 2px solid rgba($color: #000000, $alpha: 0.2);
    min-width: 320px;
}

.left-loan .rangeslider-horizontal .rangeslider__handle{
    background-color: #A3CD3A;
    background-blend-mode: unset;
}

.left-loan .title-winput-register p{
    color: black;
}

.left-loan .rangeslider__labels .rangeslider__label-item {
    color: black;
}

.right-loan{
    border-radius: 20px 20px 0 0;
    -webkit-border-radius: 20px 20px 0 0;
    -moz-border-radius: 20px 20px 0 0;
    -ms-border-radius: 20px 20px 0 0;
    -o-border-radius: 20px 20px 0 0;
    font-size: 1rem;
}

.left-dash-new .cat-prom{
    font-size: 0.9rem;
    margin-left: 0;
    @media screen and (max-width: 768px) {
      padding: 0 2rem;
    }
}

.cat-prom-bold {
    font-weight: bold;
}

.input-box-border{
    border: 1px solid #ccc;
    padding: 1rem;
}

.loan-left-title{
    width: 50%;
    text-align: left;
    font-size: 3rem;
}

.loan-right-title{
    width: 50%;
    text-align: right;
    font-size: 3rem;
}

.loan-right-title span{
    font-size: 1.3rem;
}

.amortization-loan-button{
    display: flex;
    align-items: center;
}

.slider-steps-loan{
    position: absolute;
    top: -0.8rem;
}

.left-loan .rangeslider-horizontal .rangeslider__fill {
    background-color: #A3CD3A;
}

.boton-periodicidad-loan{
    background-color: white!important;
    color: #A3CD3A!important;
    font-weight: 400;
    padding: 1rem 2rem;
    border-radius: 7px;
    margin: .5rem;
    cursor: pointer;
    border: 1px solid #A3CD3A!important;
    -webkit-border-radius: #A3CD3A !important;
    -moz-border-radius: #A3CD3A !important;
    -ms-border-radius: #A3CD3A !important;
    -o-border-radius: #A3CD3A !important;
}

.boton-periodicidad-loan:hover, .period-active-loan{
    background-color: #A3CD3A!important;
    color: white!important;
    font-weight: 600;
}

.alert {
    color: #565656 !important;
    font-size: 0.8rem;
}


.slider-error {
    background-color:rgb(255,200,200);
}

.slider-error .rangeslider__fill {
    background-color: red !important;
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0 1rem;
  }
}

.button-container button{
  margin: 10px 0;
}

.ife-container-regular {
  display: flex; 
  flex-direction: row;
  justify-content: space-between; 
  align-items:center; 
  margin-right: 30px;
  margin-bottom:10px;
  @media screen and (max-width: 850px) {
    flex-direction: column-reverse;
    margin-right: 0;
  }
}

@media(max-width: 1350px){
    .account-container{
        flex-direction: column;
    }
    .row-par{
        width: 60px;
    }
    .right-monto-option{
        border-radius: 0 20px 20px 0;
        -webkit-border-radius: 0 20px 20px 0;
        -moz-border-radius: 0 20px 20px 0;
        -ms-border-radius: 0 20px 20px 0;
        -o-border-radius: 0 20px 20px 0;
    }
}

@media(max-width: 1330px){
    .help-banner{
        margin: 1rem auto;
    }
    .pagar-container{
        border-radius: 20px;
        height: auto;
    }
}

@media(max-width: 1200px){
    .slider-container{
        border-radius: 20px;
    }
    .left-dash{
        margin: 1rem 0;
    }
    .select-option{
        border-top: none !important;
        border-bottom: none !important;
        width: 100%;
    }      
    .right-dash-new{
        display: none;
    }

    .right-dash-dropdown {
        display: block;
    }

    .button-container-dash {
        display: block;
    }

    .move-container{
        width: 100%;
        margin: 0 auto;
    }
    .resumen-right{
        width: 40%;
    }

    .instructions{
        display: grid;
        grid-template-columns: 1fr 1fr;


    }
    .ife-content {
        grid-template-columns: 1fr;
    }

    

    .cargo-container{
        width: 100%;
        box-sizing: border-box;
    }
    .pagar-container{
        width: auto;
        border-radius: 20px 20px 0 0;
        -webkit-border-radius: 20px 20px 0 0;
        -moz-border-radius: 20px 20px 0 0;
        -ms-border-radius: 20px 20px 0 0;
        -o-border-radius: 20px 20px 0 0;
    }
    .upload-container{
        flex-flow: column;
        align-items: center;
    }
    .upload-section{
        margin: 1rem 0;
        width: 100%;
        box-sizing: border-box;
        align-items: center !important;
    }
}

@media(max-width: 1000px){
    .dashboard-default {
        width: 100%;
    }
    .bank-images {
        display: grid;
        max-width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .bank-images img{
        height: auto;
        width: 80%;
        padding: 0 10%;
    }
    .right-dash{
        flex-direction: row;
    }
    .contrato-top{
        flex-direction: column;
        align-items: center;
    }
    .resumen-left, .resumen-right{
        width: 100%;
    }
    .data-margin{
        width: 100%;
    }
    .manteinance-page{
        flex-direction: column;
    }
    .left-manteinance, .right-manteinance{
        width: auto;
    }
    
    .leftLogin{
        margin-right: 20px;
    }
    .contract-resume-container{
        flex-direction: column;
    }
    .resume-dashboard-red{
        margin-bottom: 2rem;
    }
    .dash-banner{
        background-color: white;
    }
    .right-manteinance{
        display: none;
    }

    .preview-image {
        max-height: 100px;
        width: 100%;
        margin-right: 0;
    }
}


@media(max-width: 990px){
    .id-top-container {
        width: 100%;
        box-sizing: border-box;
    }
    .right-profile {
        padding: 2rem 3rem;
    }
    .id-container{
        padding: 0 2rem;
    }
    .loan-container{
        flex-direction: column;
        width: 95%;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }
    .left-loan{
         justify-content: center;
         border-radius: 20px 20px 0 0;
         -webkit-border-radius: 20px 20px 0 0;
         -moz-border-radius: 20px 20px 0 0;
         -ms-border-radius: 20px 20px 0 0;
         -o-border-radius: 20px 20px 0 0;
         border: none;
         display: inline;
    }
    .right-loan, .calculator-info-loan{
        border-radius: 0 0 20px 20px;
        -webkit-border-radius: 0 0 20px 20px;
        -moz-border-radius: 0 0 20px 20px;
        -ms-border-radius: 0 0 20px 20px;
        -o-border-radius: 0 0 20px 20px;
    }
    .app-container{
        margin-top: 0;
    }
    .move-option-container{
        flex-direction: column;
        align-items: center;
        padding: 1rem;

        @media screen and (max-width: 768px) {
          padding: 0;
          margin: 0;
        }
    }
    .move-container{
        height: auto;
    }
    .left-move-option{
        width: 100%;
    }
    .right-dash-new{
        width: 100%;
    }
    .left-dash-new{
        width: 100%;
    }
    .right-move-option{
        width: 100%;
    }
    .move-options-container{
        max-width: none;
        justify-content: center;
        flex-wrap: wrap;
    }
    .more-container{
        width: 100%;
    }

    .id-top-container h3{
        font-size: 1.4rem;
        padding-top: 20px;
    }
    .ife-description {

        margin: 0 0px;
    }
}

@media(max-width: 920px){
    .hidden-mobile {
        display: none;
    }

    .liquidate-container{
        flex-direction: column;
        align-items: center;
    }
    .buro-container{
        width: auto;
    }
    .dash-bottom-container{
        display: flex;
        margin-top: 1rem;
        justify-content: space-evenly;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .dash-box{
        margin-bottom: 1rem;
        
    @media screen and(max-width: 768px) {
      margin: 1rem;
    }
    }
    .login-container{
        flex-direction: column;
    }
    
    .rightLogin{
        width: 100%;
        margin-bottom: 4rem;
    }
    
    .leftLogin{
        width: 100%;
        padding: 2rem 0 1rem;
        margin-right: 0;
    }
    .left-monto-option{
        width: 50%;
    }
}

@media(max-width: 850px){
    .pagar-container{
        margin: 1rem;
    }
    .dash-top-container{
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
    }
    .dash-box {
        margin: 1rem;
    }
    .dashboard-default{
        height: auto;
    }
    .right-dash-new{
        width: auto;
    }
}

@media(max-width: 800px){
    .modal-bottom-container{
        flex-direction: column;
        align-items: center;
    }
    .parcialidad{
        flex-direction: column;
    }

    .modal-main-container{
        width: 90%;
        max-height: calc(90vh);
        overflow-y: auto;
    }

    // .modal-content{
    //     display: flex;
    //     flex-direction: column;
    //     padding: 0 2rem 2rem 2rem;
    //     overflow-y: auto;
    //     border-radius: 15px;
    // }
}

@media(max-width: 770px){
    .blank-div{
        display: none;
    }
    .right-move-option{
        margin-top: 2rem;
    }
    .left-move-option, .right-move-option{
        width: auto;
    }
    .more-container{
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    .left-monto-option, .right-monto-option{
        width: 85%;
    }
    .right-monto-option{
        margin-left: 0;
    }
    .new-navbar{
        top: 25px;
    }
    .contract-layer{
        flex-direction: column;
    }
    .resume-dashboard-red{
        margin-left: 0;
    }
    .top-instructions{
        width: 100%;
    }
    .slider-steps{
        width: 100%;
    }
    .more-container .slider-steps .step{
        margin-left: 5vw !important;
    }
}

@media(max-width: 700px){
    .options-buttons{
        margin: 1rem 0;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        button {
          padding: 10px 0;
        }
    }
    .flex-horizontal{
        flex-direction: column;
    }
    .right-dash-dropdown {
        width: 90%;
    }
    .more-container-main{
        flex-direction: column;
          align-items: center;
    }
    .right-monto-option{
        width: 85%;
        padding: 1rem;
        padding-top: 0;
    }
    .new-loan{
        width: 100%;
        margin: 1rem 0;
        padding: 0;
    }
    .amount-calculator-info{
        padding: 0;
        margin: 0;
        width: 100%;
    }
    .btn.more-button{
        display: none;
    }
    .btn.more-button-mobile{
        display: block;
        margin-top: 1rem;
        border-radius: 5px;
    }
    .flex-horizontal p{
        text-align: center;
    }
    .flex-horizontal a{
        margin: 1rem;
    }
    .profile-top-home{
        flex-direction: column;
    }
    .profile-top-home p {
        margin: 1rem 0 0;
    }
    .profile-home {
        display: flex;
        background-color: white;
        max-width: 95%;
        margin: auto;
        flex-direction: column-reverse;
    }
    .left-profile{
        width: 100%;
        display: flex;
        box-shadow: none;
        padding: 0 0 3rem 0;
    }
    .right-profile{
        width: 100%;
        padding: 0;
    }
    .prestamo-texto{
        width: 100%;
    }
    .prestamo-bars{
        max-width: 280px;
    }
}

@media(max-width: 650px){
    .manteinance-page{
        margin: 0.5rem 3rem 2rem;
    }
    .loan-left-title, .loan-right-title{
        font-size: 2rem;
    }
}

@media(max-width: 630px){
    .contrato-top{
        flex-direction: column;
        align-items: center;
    }
}

@media(max-width: 600px){
    .login-button{
        width: auto !important;
    }

    .leftLogin{
        width: 100% !important;
        padding: 1rem 2rem !important;
        box-sizing: border-box !important;
    }
    .amortization-loan-button{
        flex-direction: column;
    }
    .amortization-loan-button > p{
        margin-top: 1rem !important;
    }
    .right-dash-new{
        flex-direction: column;
        width: 100%;
    }
    .right-dash-new > div:nth-child(3){
        border-left: none;
        border-right: none;
        border-top: 1px solid black !important;
        border-bottom: 1px solid black !important;
    }
    .select-option{
        width: auto;
    }
}

@media(max-width: 550px){
    .ife-container{
        flex-direction: column;
        align-items: center;
    }
    .dash-top-container{
        flex-direction: column;
    }
    .right-dash{
        flex-direction: column;
        width: 100%;
    }
    .react-tabs__tab-list {
        display: flex;
        flex-direction: column;
    }
    .react-tabs__tab {
        border: 1px solid #A7CE3A;
    }
    .liquidate-right{
        margin: 1rem;
        width: 90%;
    }
    .data{
        flex-direction: column;
    }
    .buttons-wrapper{
        flex-direction: column;
    }
    .cargo-container{
        padding: 1rem 10px;
    }
    .resume-dashboard-red{
        width: auto;
        margin-right: 0;
    }
    .move-container{
        width: 100%;
    }
    .account-container {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        justify-content: center;
        flex-direction: column;
    }
    .resumen-left h2{
        margin: 1rem 0;
        text-align: center;
    }
    .data{
        align-items: center;
    }
}

@media(max-width: 480px){
    

    .flex-distributed{
        margin-bottom: 1rem;
    }
    .flex-distributed img{
        width: 60px;

    }
    .login-button{
        flex-direction: column;
        width: 200px !important;
        align-items: baseline !important;
    }

    .no-margin-mquery{
        margin: 1rem 0 !important;
    }

    .contrato-bottom{
        flex-direction: column;
    }
    .contrato-bottom a{
        margin: 2rem 0 0;
    }
    
    .contrato-bottom p{
        margin-top: 1rem;
    }
    .pagar-container h3{
        text-align: center;
    }
    .liquidar-prestamo {
        justify-content: center;
        align-items: center;
        width: 280px;
        margin: 0 auto;
    }
    .op-right-buttons img{
        width: 310px !important;

    }
    .modal-accordion{
        width: 310px;
    }
    .logo-vivus img{
        width: 50% !important;
    }
    .educacion-fin{
        padding: 1rem 0;
    }
    .educacion-fin input{
        max-width: 230px;
    }
    .right-check-text{
        max-width: 300px;
    }
    .right-check-text p{
        text-align: justify;
    }
    .btn-minimal-width{
        margin: 0 auto;
        max-width: 250px;
        font-size: 1rem !important;
    }
    .op-left-buttons{
        margin: 1rem 0;
    }
    .options-buttons{
        flex-wrap: nowrap;
    }
}

@media (max-width: 479px){
    header .header-logo .logo {
        width: auto;
    }
}

@media (max-width: 430px){
    .loan-number p{
        font-size: 1rem;
    }
    .password-wrapper{
        flex-wrap: wrap;
    }
    .show-password{
        margin:  20px 0;
    }
    .resume-dashboard-red{
        min-width: auto;
    }
    .friends-container{
        width: 95%;
        margin: 0 auto;
    }
    .flex-horizontal{
        padding: 0;
    }
    .prestamo-bars{
        max-width: 250px;
    }
    .dash-banner video{
        width: 250px !important;
    }
    .leftLogin input{
        width: auto !important;
    }
    
    .modal-main-container {
        margin-top: 2rem;
    }
    .modal-content p{
        padding: 0rem;
    }
}

@media (max-width: 380px) {
    .flex-distributed{
        margin-bottom: 0.1rem;
    }
    .flex-distributed img{
        width: 45px;

    }
}


.privacy-row {
  @media screen and (max-width: 850px) {
    flex-direction: column;
    
  }
}

.privacy-row > div {
  @media screen and(max-width: 850px) {
    width: auto !important;
    max-width: 100%;
    min-width: auto;
  }
}

.privacy-container p {
  margin: 1rem 0 !important;
}