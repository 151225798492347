/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input:checked + div {
  @apply border-green-dark;
}
input:checked + div svg {
  @apply block;
}

.rc-steps-navigation .rc-steps-item-active .rc-steps-item-container {
  border-bottom: 3px solid #a3cd3a !important;
}

html {
  scroll-behavior: smooth;
}

input {
  font-size: 16px;
}

input[type="text"],
input[type="number"],
input[type="search"],
input[type="email"],
input[type="password"],
input[type="phone"] {
  @apply h-10;
  @apply outline-none;
  @apply shadow-sm;
  @apply border;
  @apply rounded-lg;
  @apply text-black-primary;
  @apply px-4;
  @apply transition-all;
  @apply focus:border-green-primary focus:shadow-green-primary;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="phone"]:focus {
  @apply border-green-primary shadow-green-primary;
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
