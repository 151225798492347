a{
    color: #ffffff;
    text-decoration: none;
}

.flex-horizontal-new{
    display: flex;
    justify-content: space-between;
    padding: 0 9rem;
    align-items: flex-start;
}

.flex-menu-home, .flex-menu-section{
    display: flex;
}

.flex-menu-section {
  margin: 0;
}

.flex-menu-home li{
    list-style-type: none;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
}

.flex-menu-home li a{ 
    color: gray;
}

.flex-menu-home li:nth-last-child(1) a{ 
    color: white;
}

.flex-menu-section{
    background-color: white;
}

.flex-menu-section li{
    list-style-type: none;
    padding: 1.5rem 1rem;
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid transparent;
}

.flex-menu-section a{
    color: #A3CD3A;
}

.active-go{
    border-bottom: 5px solid rgb(126, 158, 45) !important;
    font-weight: 600;
}

.menu-button-home{
    background-color: #A3CD3A;
    border-radius: 3px;
    cursor: pointer;
}

.navbar{
    z-index: 1;
    position: relative;
    align-items: center;
    background: white;
}

.menu-client-home{
    list-style-type: none;
    margin: 0;
    padding-inline-start: 0;
}
.menu-client-home li{
    padding: 0.5rem 1rem;
    margin-left: 0;
}

.home{
    margin-top: -94px;
    z-index: 0;
}

.social{
    position: sticky;
    bottom: -1px;
    z-index: 10;
    margin-top: -2.3rem;
}

.quick-contacts-content{
    border-radius: 12px 12px 0 0;
    padding: 8px 0 4px 0px;
    background-color: #313a3d;
    width: 150px;
    margin: -1px 80%;
}

.quick-contacts-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.quick-contacts-content ul li {
    display: inline-block;
    font-size: 1.1rem;
    padding: 0 12px;
    color: #ffffff;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #ffffff;
}

.quick-contacts-content ul li:last-child {
  border-width: 0;
}

.clickable{
    cursor: pointer;
}

// FOOTER
.footer-container{
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    background-color: #A7CE3A;
    flex-wrap: wrap;
    color: white;
    padding: 1rem 5rem 0 5rem;
    position: relative;
}

.left-footer{
    display: flex;
    width: 48%;
}

.chat-section{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-evenly;
}

.chat-section h2{
    font-size: 2rem;
    margin: 0 1rem;
}

.chat-section p{
    font-size: 1.2rem;
    margin: 1rem;
    font-weight: 600;
    text-align: left;
    padding: 0 5rem 0 0;
}

.right-footer {
    width: 52%;
}

.contact-icons {
    display: flex;
    padding: 2rem 0 1rem 0;
}

.contact-icon {
    margin-right: 2rem;
}

.contact-icon a{
    color: white;
}

.contact-icon svg{
    font-size: 1.8rem;
}

.bussiness-hours{
    font-size: 1rem;
    text-align: left;
}

.footer-links-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
}

.footer-links-container a{
    margin: 0 1rem;
    color: gray;
    text-decoration: underline;
    font-size: 0.8rem;
}

.whatsapp{
    position: absolute;
    bottom: 4px;
    right: 4rem;
}

.whatsapp img{
    width: 50px;
    height: 50px;
}

.flex-menu-home-mobile{
    opacity: 0;
    position: absolute;
    top: -13rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    transition: opacity 100ms ease;
    background-color: rgba(81, 81, 81, 0.5);
}

.flex-menu-home-mobile a{
    color: white !important;
}

.flex-menu-mobile{
    opacity: 0;
    position: absolute;
    top: -20rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    transition: opacity 100ms ease;
}

.mobile-open{
    opacity: 1;
    padding: 1rem;
    top: 1.5rem;
}

.f-popup-container{
    text-align: justify;
    padding: 1rem 2rem;
    font-weight: 300;
    display: flex;
}

.text-f-popup-container{
    padding: 0 2rem;
    font-size: 0.7rem;
}

.image-f-popup-container{
    width: 300px;
    margin: auto;
}

.image-f-popup-container img{
    width: inherit;
}

@media(max-width: 1450px){
    .quick-contacts-content{
        margin: 0 75%;
    }
}

@media(max-width: 1170px){
    .footer-container{
        padding: 1rem 0 0 0;
    }
}

@media(max-width: 1150px){
    .quick-contacts-content{
        margin: 0 65%;
    }
}

@media(max-width: 950px){
    .left-footer, .right-footer{
        width: 50%;
    }
    .quick-contacts-content{
        display: none;
    }

    .flex-horizontal-new {
      padding: 1rem 2rem;
    }
}

@media(max-width: 850px){
    .footer-container{
        flex-direction: column-reverse;
    }
    .right-footer{
        margin-bottom: 2.5rem;
    }
    .left-footer, .right-footer{
        width: 100%;
        text-align: center;
    }
    .contact-icons{
        justify-content: center;
        flex-direction: column;
    }
    .bussiness-hours{
        text-align: center;
    }
    // .quick-contacts-content{
    //     margin: 0 60%;
    // }
    .flex-horizontal-new{

        justify-content: baseline;
    }
}

@media(max-width: 750px){
    // .quick-contacts-content{
    //     margin: 0 55%;
    // }
}

@media(max-width: 630px){
    .footer-links-container{
        flex-direction: column;
    }

    // .quick-contacts-content{
    //     margin: 0 50%;
    // }
    .flex-horizontal-new img{
        width: 160px;
    }
    .whatsapp{
        left: 0%;
        width: 13%;
        filter: opacity(0.5);
        -webkit-filter: opacity(0.7);
    }
    .whatsapp:hover{
        filter: opacity(1);
        -webkit-filter: opacity(1);
}
}

@media(max-width: 650px){    
    .flex-menu-section li{
        padding: 0.5rem 0;
        text-align: center;
    }
    .flex-menu-section li a{
      width: 100%;
    }
}

@media(max-width: 580px){
    // .quick-contacts-content{
    //     margin: 0 35%;
    // }
    .navbar div{
        font-size: 1.7rem !important;
        margin: 0 !important;
    }
    .menu-client-home a{
        font-size: 0.8rem;
    }
}

@media(max-width: 450px){
    .left-footer{
        flex-direction: column-reverse;
        align-items: center;
    }
    .left-footer-img{
        width: fit-content;
    }
    .chat-section{
        align-items: center;
        margin-bottom: 2rem;
    }
    .chat-section p{
        padding: 0;
        margin: 0;
        text-align: center;
    }
    // .quick-contacts-content{
    //     margin: 0 20%;
    // }
    .flex-horizontal-new > ul{
        width: 120px;
        text-align: center;
    }
    .flex-horizontal-new > a, .flex-horizontal-new > div{
        width: 50%;
        max-width: fit-content;
    }
    .menu-button-home{
        padding: 5px 0 !important;
    }
    .navbar div{
        font-size: 1.2rem !important;
    }
    .flex-horizontal-new img{
        width: 120px;
    }
    .flex-menu-home li{
        display: block;
    }
}

@media(max-width: 400px){
    .navbar {
        padding: 1rem 2rem 1rem 2rem;
    }
    .menu-client-home a{
        font-size: 0.7rem;
    }
    .flex-horizontal-new > ul{
        width: 100px;
    }
}

@media(max-width: 360px){
    // .quick-contacts-content{
    //     margin: 0 10%;
    // }
}